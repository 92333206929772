.info-card-tall {
  max-width: 300px;
  // min-width: 20vw;
  height: 100%;
  // margin: 5px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  padding: 15px;
  position: relative;

  .icon-container {
    height: 70px;
    margin-bottom: 10px;
    .icon {
      height: 100%;
      width: auto;
    }
  }

  .text-header {
    margin-bottom: 10px;
    color: rgb(138, 23, 49);
    font-family: 'Josefin Sans', sans-serif;
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;

  }

  .text-content {
    color: rgb(19, 19, 19);
    font-size: 16px;
    font-weight: 400;
    // white-space: pre-line;
    margin-bottom: 60px;
    cursor: pointer;
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    .custom-button {
        transition: .15s ease-in-out;
        background-color: rgba(138,23,49,1);
        color: white;
        border: 1px solid rgba(138,23,49,1);

    }
  }

  .button-bottom {
    width: 90%;
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
  }


}