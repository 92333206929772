.custom-button {
  // min-width: 100px;
  width: 100%;
  max-width: 200px;
  height: 45px;
  letter-spacing: 0.5px;
  line-height: 40px;
  padding: 0 35px 0 35px;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Mulish', sans-serif;
  font-weight: bolder;
  cursor: pointer;

  background-color: white;
  color: rgba(138,23,49,1);
  border: 1px solid rgba(138,23,49,1);

  &:hover {
    transition: .15s ease-in-out;
    background-color: rgba(138,23,49,1);
    color: white;
  }

  &.inverted {
    background-color: rgba(138,23,49,1);
    color: rgb(250, 250, 250);

    &:hover {
      transition: .15s ease-in-out;
      background-color: rgb(250, 250, 250);
      color: rgba(138,23,49,1);
    }
  }

  &.footer-image-color-style {
    border: 1px solid rgb(250, 250, 250);
    // color: rgba(28, 39, 48, .9);
    color: black;

    &:hover {
      transition: .15s ease-in-out;
      background-color: transparent;
      color: rgb(250, 250, 250);
      border: 1px solid rgb(250, 250, 250);

    }
  }

  &.transparent {
    border: 1px solid rgb(250, 250, 250);

    &:hover {
      transition: .15s ease-in-out;
      background-color: transparent;
      color: rgb(250, 250, 250);
      border: 1px solid rgb(250, 250, 250);

    }
  }
}
