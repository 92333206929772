.info-card-team {
  width: 400px;
  margin: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  .info-card-team-image {
    height: 420px;
    // width: 100%;
    background-position: center;
    background-size: cover;
  }

  .info-card-team-title {
    margin: 10px;
    color: rgb(138, 23, 49);
    font-family: 'Josefin Sans', sans-serif;
    font-size: 20px;
    font-weight: 400;
    cursor: pointer;
  }

  .info-card-team-text {
    margin: 10px;
    font-size: 18px;
  }

  
}

@media all and (max-width: 815px) {
  .info-card-team {
    width: auto;

    .info-card-team-image {
    background-position: center 25%;
    // background-size: auto;
    background-repeat: no-repeat;
  }


  }
}