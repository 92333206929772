.footer {
  
  .contact-info-container {
    padding: 50px;

    display: flex;
    flex-direction: column;

    align-items: center;

    .logo-container {
      margin-bottom: 20px;
      .logo {
        height: 100px;
      }
    }

    .contact-info {
      width: 100%;
      max-width: 900px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .container {
        border: 1px solid rgb(19,19,19);
        margin: 10px;
        display: inline-flex;
        align-items: center;

          
          .contact-icon {
            width: 50px;
            height: 50px;
            background-position: center;
          }

        .contact-text {
          padding: 0 25px;
        }
      }
    }
  }

  .social-info {
    margin-top: 10px;
    .fa-icon {
      margin-right: 10px;
      margin-left: 10px;
    }

    .fb {
      color: #4267B2;
    }

    .location {
      color: black;
    }

    .fa-icon {
      &:hover {
        transform: scale(1.1);
        transition: transform .15s cubic-bezier(0.25, 0.45, 0.45, 0.95);
      }
    }
  }

  .copyright {
    text-align: center;
    padding: 10px;
    background-color: rgb(245, 245, 245);
  }

  @media all and (max-width: 893px) {
    .contact-info {
      max-width: 300px !important;
      margin: 0 auto;
      align-items: center;
      justify-content: center !important;
    }
    .contact-text {
      display: none;
    }
  }
  
}