.info-image {
  position: relative;
  min-width: 400px;
  height: 22vw;
  max-height: 400px;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 7.5px 15px;
  overflow: hidden;


  &:hover {
    cursor: pointer;

    & .background-image {
      transform: scale(1.1);
      transition: transform .5s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    }

    & .text-container {
      opacity: 0.9;
      background-image: 
      linear-gradient(
        rgba(28, 39, 48, .8), 
        rgba(28, 39, 48, .8)
      );
      transition: transform .5s cubic-bezier(0.25, 0.45, 0.45, 0.95);

      .text-header,
      .text-content {
        transform: scale(0.9);
      }
    }

    & .text-containter-title {
      opacity: 0;
      // transform: translatey( -10px );
      transition: .1s cubic-bezier(0.25, 0.45, 0.45, 0.95);
    }

  }

  &:first-child {
    margin-right: 7.5px;
  }

  &:last-child {
    margin-left: 7.5px;
  }

  .background-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: transform .25s ease-out;

  }

  .text-container {
    height: 100%;
    padding: 10px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    position: absolute;
    transition: .25s ease-out;


    .text-header {
      font-family: 'Josefin Sans', sans-serif;
      font-weight: bold;
      margin: 0 6px 0;
      font-size: 28px;
      color: rgba(255,255,255,1);
      transition: .25s ease-out;

    }

    .text-content {
      font-weight: 400;
      font-size: 18px;
      color: rgba(255,255,255,1);
      transition: .25s ease-out;

    }
  }

  .text-containter-title {
    // height: 70%;
    // padding: 10px 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0.9;
    position: absolute;

    .text-header {
      font-family: 'Josefin Sans', sans-serif;
      font-weight: bold;
      // margin: 0 6px 0;
      font-size: 30px;
      color: rgba(255,255,255,1);
      transition: .25s ease-out;
      width: 100%;

      justify-content: center;
        font-family: 'Josefin Sans', sans-serif;
        font-weight: 400;
        color: rgb(241, 241, 241);
        // font-size: 46px;
        text-decoration-style: solid;
        text-shadow: rgb(0, 0, 0) 1px 1px 2px;
        background: linear-gradient(0deg, rgba(0,212,255,0) 0%, rgba(0,0,0,.7) 33%, rgba(0,0,0,.7) 67%, rgba(0,255,255,0) 100%);
        padding: 35px 0;

    }
  }
}
