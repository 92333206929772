* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  border: none;
  // scroll-behavior: auto;
  // font-family: 'Ubuntu', sans-serif;
  // font-weight: 500;
}

body {
//   // font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  background-color: transparent;
  font-family: 'Mulish', sans-serif;
  font-weight: 400;
  margin-top: 70px;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

p {
  caret-color: transparent;
  cursor: default;
}

.grey {
  color: #d3d8dc;
}

.disable-highlight {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.clickable {
  cursor: pointer;
}

.header-container {
  width: 100%;
  background-color: rgb(245, 245, 245);

}
.header-image {
  max-width: 1920px;
  margin: 0 auto;
  
}

.header-logo-mobile {
  display: none;
}
.nav-container {
  display: inline-flex;
}

.mobile-nav-container {
  display: none;
}

@media all and (max-width: 1010px) {
  
  .nav-container {
    display: none;
  }

  .mobile-nav-container {
    display: grid;
  }

}

@media all and (max-width: 560px) {
  .header-logo-desktop {
    display: none;
  }

  .header-logo-mobile {
    display: block;
  }

}


@media all and (max-width: 815px) {
  .section {
    .info-card-tall-container {
      display: block;
      .info-card-tall {
        max-width: none;
        margin-bottom: 20px;

      }
    }
  }
}

// @media all and (max-width: 800px) {
//   .header-image {
//     max-height: 400px;
//     min-height: 0;
//   }
// }

@media all and (max-width: 750px) {
  .section {
    .info-card-tall-container {
      display: block;
      .info-card-tall {
        max-width: none;
        margin-bottom: 20px;

      }
    }
    
    .info-tile-container {
      .info-tile-left,
      .info-tile-right {
        display: block !important;

        .info-tile-text-container {
          padding: 0 0 !important;
          .info-tile-title {
            text-align: center;
          }
        }
        .info-tile-picture {
          display: none;
        }
      }
    }
  }
}

@media all and (max-width: 569px) {
  .mobile-hide {
    display: none;
  }
  .pad-number {
    padding-right: 0px !important;
  }
}

@media all and (max-width: 504px) {
  .header-image {
    padding: 40px 10px;
  }
  .section {
    padding: 100px 15px !important;
  }
}
