.section-break {
  width: 100%;
  position: relative;

  .logo-divider {
    width: 65px;
    height: 65px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;

    background-image: url(https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/Layer+116-78w.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

  }
}