.consulting-and-advisory {
  .header-image {
    height: calc(100vh - 70px);
    max-height: 400px;
    width: 100%;
    align-items: center;
    padding: 40px 50px;
    // background-image: 
    //   linear-gradient(
    //     rgba(28, 39, 48, .2), 
    //     rgba(28, 39, 48, .2)
    //   ),
    //   url(https://lirp.cdn-website.com/a6ee6966/dms3rep/multi/opt/Tax_Consultants-James-Wailuku-001-2880w.jpg);
    background-size: cover;
    background-position: center;
    display: flex;

    .content-container{
      max-width: 1050px;
      width: 100%;
      height: 100%;
      margin: 0 auto;

      display: inline-flex;
      align-items: center;

      .header-text-container {
        display: flex;
        flex-direction: column;

        .header-text {
          max-width: 600px;
          display: flex;
          justify-content: center;
          font-family: 'Josefin Sans', sans-serif;
          font-weight: 400;
          color: rgb(241, 241, 241);
          font-size: 46px;
          text-decoration-style: solid;
          text-shadow: rgb(0, 0, 0) 1px 1px 2px;
          text-size-adjust: 100%;
          float: left;
          margin-bottom: 20px;
          
        }
      }
    }
  }

  .section {
    padding: 100px 50px;
    width: 100%;
    align-items: center;
    text-align: center;

    &.odd {
      background-color: rgb(245, 245, 245);
    }

    .title {
      color: rgba(138,23,49,1);
      font-family: 'Josefin Sans', sans-serif;
      font-size: 35px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    .subtitle {
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      margin-bottom: 20px;
    }

    .info-card-short-container {
      display: inline-flex;
      padding-bottom: 30px;

      .info-card-short {
        margin: 0 10px;
        padding: 20px 25px 15px 25px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 11px 0px;

        display: inline-flex;
        


        .icon-container {
          width: 40px;
          margin-right: 15px;
          background-repeat: no-repeat;

          .icon {
            width: 100%;
            padding: 10px 0;
          }
        }

        .text-container {
          font-weight: 400;

          .text-header {
            font-size: 18px;
            line-height: 27px;
  
          }
          .text-content {
            color: rgb(138, 23, 49);
            font-size: 20px;
            font-weight: 700;
            line-height: 30px;
          }
        }
      }
    }

    .info-card-tall-container {
      width: 100%;
      display: inline-flex;
    }
    
    .section-text {
      max-width: 1000px;
      margin: 0 auto;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;

    }

    .info-image-container {
      width: 100%;
      max-width: 1000px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 auto;
    }

    .info-tile-container {
      width: 100%;
      max-width: 1000px;

      display: grid;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0 auto;

      .info-tile-left {
        grid-template-columns: 1fr 1fr;
      }

      .info-tile-right {
        grid-template-columns: 1fr 1fr;
      }


      .info-tile-left,
      .info-tile-right {
        display: grid;
        gap: 40px;
        padding: 20px 0;

        text-align: left;

        .info-tile-text-container {
          padding: 40px 0;

          .info-tile-title {
            color: rgba(138,23,49,1);
            font-family: 'Josefin Sans', sans-serif;
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 10px;
          }
        }
        .info-tile-picture {
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

          background-size: cover;
          background-position: center;
        }
      }
    }
  }
}