.link-button {
  // width: 187;
  // height: 48px;
  // letter-spacing: 0.5px;
  // padding: 24px 35px 24 35px;
  // font-size: 15px;
  // background-color: black;
  // color: white;
  // text-transform: uppercase;
  // // font-family: 'Open Sans Condensed';
  // font-weight: bolder;
  // border: none;
  // cursor: pointer;
  // box-sizing: content-box;
  // padding: 12px;
  width: 170px;
  height: 45px;
  border-color: rgba(138,23,49,1);
  border-width: 1px;
  border-style: solid;
  text-decoration: none;
  text-align: center;
  font-weight: 700;
  line-height: 45px;
  color: rgba(138,23,49,1);
  margin-right: 10px;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #fff;
    background-color: rgba(138,23,49,1);
  }
}

@media all and (max-width: 360px) {
  .link-button {
    width: 75px;
    .link-button-text {
      display: none;
    }
  }
}
